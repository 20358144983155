<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">新建项目记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <div>
                <MapDraw
                    :basicMap="taskMap"
                    :drawConf="drawConf"
                    :jsonStr="jsonStr"
                    @update:jsonStr="getJsonStr"
                >
                </MapDraw>
                <Mymap
                    ref="myMap"
                    class="map"
                    :mapData="mapData"
                    :myMap.sync="taskMap"
                >
                </Mymap>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <div class="flex-between" style="width: 100%">
                    <div style="width: 50%">
                        <el-form-item
                            prop="XJXMBH"
                            label="项目编号"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="formdata.dataDic.XJXMBH"
                                :disabled="!isEdit"
                                :maxlength="50"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="遗产组成"
                            prop=""
                            class="from-item-block"
                        >
                            <el-select
                                :disabled="!isEdit"
                                v-model="formdata.dataDic.YCZC"
                                filterable
                                clearable
                                @change="getYcys()"
                            >
                                <el-option
                                    v-for="(item, index) in yczcList"
                                    :key="index"
                                    :label="item"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="遗产要素"
                            prop=""
                            class="from-item-block"
                        >
                            <el-select
                                :disabled="!isEdit"
                                v-model="formdata.dataDic.YCYSBM"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="v in ycysList"
                                    :key="v.BM"
                                    :label="v.MC"
                                    :value="v.BM"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            prop="XMMC"
                            label="项目名称"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="formdata.dataDic.XMMC"
                                :disabled="!isEdit"
                                :maxlength="100"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop="JSMD"
                            label="建设目的"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="formdata.dataDic.JSMD"
                                resize="none"
                                type="textarea"
                                :disabled="!isEdit"
                                :autosize="{ minRows: 2, maxRows: 6 }"
                                :maxlength="500"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop="GD"
                            label="高度(m)"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="formdata.dataDic.GD"
                                :disabled="!isEdit"
                                @input="toDecimal($event, 'GD')"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div style="width: 50%">
                        <el-form-item
                            prop="KGRQ"
                            label="开工日期"
                            class="from-item-block"
                        >
                            <el-date-picker
                                v-model="startTime"
                                placeholder="选择开工日期"
                                type="month"
                                value-format="yyyy-MM"
                                format="yyyy-MM"
                                :disabled="!isEdit"
                                @change="changeStartTime()"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item
                            prop="JGSJ"
                            label="竣工日期"
                            class="from-item-block"
                        >
                            <el-date-picker
                                v-model="endTime"
                                placeholder="选择竣工日期"
                                type="month"
                                value-format="yyyy-MM"
                                format="yyyy-MM"
                                :disabled="!enableEditDate || !isEdit"
                                @change="changeEndTime()"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item
                            prop="SFWJ"
                            label="是否征得文物部门同意"
                            class="from-item-block"
                        >
                            <el-select
                                v-model="formdata.dataDic.SFWJ"
                                :disabled="!isEdit"
                            >
                                <el-option
                                    v-for="(item, index) in SFWJList"
                                    :key="`${index}`"
                                    :value="item.CODE"
                                    :label="item.LABEL"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            v-if="formdata.dataDic.SFWJ === '1'"
                            prop="WWBMPZXKWH"
                            label="文物部门批准许可文号"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="formdata.dataDic.WWBMPZXKWH"
                                :disabled="!isEdit"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop="ZDMJ"
                            label="占地面积(㎡)"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="formdata.dataDic.ZDMJ"
                                :disabled="!isEdit"
                                @input="toDecimal($event, 'ZDMJ')"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            prop="JSKZYQ"
                            label="建设控制要求"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="formdata.dataDic.JSKZYQ"
                                resize="none"
                                type="textarea"
                                :disabled="!isEdit"
                                :autosize="{ minRows: 2, maxRows: 6 }"
                                :maxlength="500"
                            ></el-input>
                        </el-form-item>
                    </div>
                </div>

                <div class="flex-between">
                    <el-form-item
                        label="建设地点"
                        class="from-item-block"
                        prop=""
                    >
                        <el-select
                            v-model="formdata.dataDic.WZSM"
                            :disabled="!isEdit"
                            :clearable="true"
                        >
                            <el-option
                                v-for="(item, index) in JSDDList"
                                :key="`${index}`"
                                :value="item.CODE"
                                :label="item.LABEL"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label=" "
                        class="from-item-block"
                        prop="QTJSDD"
                    >
                        <el-select
                            v-model="formdata.dataDic.QTJSDD"
                            :disabled="!isEdit"
                            :clearable="true"
                        >
                            <el-option
                                v-for="(item, index) in QTJSDD"
                                :key="`${index + 99}`"
                                :value="item.CODE"
                                :label="item.LABEL"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <el-form-item prop="" label="项目调研" class="from-item-block">
                    <el-select
                        v-model="formdata.dataDic.XMDYID"
                        :disabled="!isEdit"
                        clearable
                        @change="xmdyChange()"
                    >
                        <el-option
                            v-for="(item, index) in xmdyList"
                            :key="index"
                            :value="item.ID"
                            :label="item.XMMC"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="调研附件" class="from-item-block" prop="">
                    <div class="imgPart scrollbar">
                        <SnFileDialog :fileData="fileData"> </SnFileDialog>
                    </div>
                </el-form-item>
                <el-form-item
                    prop="BZDW"
                    label="新建项目工程方案"
                    class="from-item-block"
                >
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="projectList"
                        basicType="document"
                        :disabled="!isEdit"
                        :maxSize="500"
                    ></basic-upload>
                </el-form-item>
                <el-form-item
                    prop="BZDW"
                    label="工程方案批复文件"
                    class="from-item-block"
                >
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="gcfapfwjList"
                        basicType="documentWordPdf"
                        :limit="1"
                        :disabled="!isEdit"
                        :maxSize="500"
                    ></basic-upload>
                </el-form-item>

                <!-- <el-form-item
                    prop="BZDW"
                    label="示意图"
                    class="from-item-block"
                >
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="sytList"
                        basicType="picture"
                        :disabled="!isEdit"
                        :maxSize="500"
                    ></basic-upload>
                </el-form-item> -->
            </el-form>
        </div>
    </div>
</template>

<script>
import MapDraw from "@comp/map/mapDraw/MapDraw";
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "xjxm",
    components: {
        MapDraw,
    },
    mixins: [infoMixin],
    data() {
        return {
            mapData: {
                zoom: 12.6,
                baseMapNum: 1,
                mapTypeBtn: false,
                areaState: true,
                dxtState: true,
            },
            isEdit: false,
            saveButton: true,
            xmdyList: [], //项目调研列表
            formdata: {
                itemCode: "90201",
                dataDic: {
                    glycbtid: this.heritageId,
                    XMWZ: "",
                    YCZC: "",
                    YCYSBM: "",
                    XJXMBH: "",
                    XMMC: "",
                    JSMD: "",
                    WZSM: "",
                    QTJSDD: "",
                    KGRQ: null,
                    JGSJ: null,
                    SFWJ: "",
                    WWBMPZXKWH: "",
                    ZDMJ: "",
                    GD: "",
                    JSKZYQ: "",
                    CJRID: localStorage.userId,
                    RWID: JSON.parse(this.$route.query.val).RWID,
                    XMDYID: "",
                },
            },
            rules: {
                XMMC: [
                    {
                        required: true,
                        message: "请输入项目名称",
                        trigger: "blur",
                    },
                ],
                QTJSDD: [
                    {
                        required: true,
                        message: "请输入建设地点",
                        trigger: "blur",
                    },
                ],
                bhgljgmc: [
                    {
                        required: true,
                        message: "请填写保护管理机构名称",
                        trigger: "blur",
                    },
                ],
            },
            enumData: [],
            JSDDList: [],
            QTJSDD: [],
            SFWJList: [],
            projectList: [],
            gcfapfwjList: [], //工程方案批复文件
            sytList: [],
            drawConf: {
                position: "topleft",
                show: true,
                draw: {
                    polygon: true, //多边形
                    polyline: false,
                    rectangle: true, //矩形
                },
            },
            jsonStr: null,
            taskMap: null,
            startTime: "", //开始时间
            endTime: "", //结束时间
            yczcList: [], // 遗产组成
            ycysList: [], // 遗产要素
            mp3List: [],
            mp4List: [],
            imgList: [],
            pqList: [], //片区
            fileData: {
                mp3List: [],
                mp4List: [],
                imgList: [],
                othersPart: {
                    key: "",
                    name: "",
                    list: [],
                },
            },
            xmdyGroup: "", //项目调研图层组
        };
    },
    watch: {
        projectList: {
            deep: true,
            handler: function () {
                this.getFile();
            },
        },
        sytList: {
            deep: true,
            handler: function () {
                this.getFile();
            },
        },
        gcfapfwjList: {
            deep: true,
            handler: function () {
                this.getFile();
            },
        },
        formdata: {
            deep: true,
            handler: function () {
                if (this.formdata.dataDic.XMWZ) {
                    this.jsonStr = this.formdata.dataDic.XMWZ;
                }
            },
        },
    },
    created() {
        this.getYczcList();
        this.getYcys();
    },
    mounted() {
        this.getXmdyList();
        this.GetEnum();
        this.getAreaInfo();
        this.xmdyGroup = window.L.layerGroup().addTo(this.taskMap);
    },
    methods: {
        ...mapActions([
            "getAllEnumInfobytableID",
            "XjxmGetXmdyList",
            "GetRcxcXcpq",
            "GetYczcListByYcys",
            "GetYcysListByYczc",
        ]),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 获取遗产组成列表
        async getYczcList() {
            let result = await this.GetYczcListByYcys({
                ycysbm: "",
            });
            let list = [];
            result.RESULTVALUE.map((item) => {
                list.push(item.YCYSFL_2);
            });
            this.yczcList = Array.from(new Set(list));
        },
        async getYcys() {
            this.formdata.dataDic.YCYSBM = "";
            let res = await this.GetYcysListByYczc({
                yczc: this.formdata.dataDic.YCZC
                    ? this.formdata.dataDic.YCZC
                    : "",
            });
            this.ycysList = res.RESULTVALUE;
        },
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "90201",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "WZSM") {
                    this.JSDDList = v.dataItems;
                }
                if (v.COLUMNID === "SFWJ") {
                    this.SFWJList = v.dataItems;
                    // 初始化 是否征得文物部门同意 赋值
                    if (!this.id) {
                        this.formdata.dataDic.SFWJ = "1";
                    }
                }
                if (v.COLUMNID === "QTJSDD") {
                    this.QTJSDD = v.dataItems;
                }
            });
        },
        // 获取项目调研列表
        async getXmdyList() {
            let result = await this.XjxmGetXmdyList({});
            this.xmdyList = result.RESULTVALUE;
        },
        getFile() {
            let listid = [];
            let list = [];
            this.projectList.forEach((v) => {
                listid.push(`9020101_${v.jcsjid}`);
                list.push(v);
            });
            this.gcfapfwjList.forEach((v) => {
                listid.push(`9020102_${v.jcsjid}`);
                list.push(v);
            });
            this.formdata.fileids = listid.join(",");
            this.formdata.metaJson = list;
        },
        // 标绘操作
        getJsonStr(JsonStr, event) {
            let jsonStr = JSON.parse(JsonStr);
            // 在地图上画形状 获取遗产要素 功能取消掉
            // this.getYczy(jsonStr);
            if (this.operateMarker) {
                this.taskMap.removeLayer(this.operateMarker);
            }
            // if (jsonStr.length === 0) return false;
            // let json = jsonStr[jsonStr.length - 1].geometry;
            // if (json.type !== 'Polygon') return false;
            this.pointJson = jsonStr;
            let typeList = ["draw:created", "draw:editstop", "draw:deleted"]; // 创建、编辑、删除
            if (typeList.indexOf(event.type) > -1) {
                this.formdata.dataDic.XMWZ = JsonStr;
            }
        },
        //获取片区数据
        async getAreaInfo() {
            let result = await this.GetRcxcXcpq({});
            this.pqList = result;
        },
        // 获取遗产组成
        getYczy(jsonStr) {
            this.yczcList = [];
            jsonStr.map((item) => {
                this.pqList.map((itemInner) => {
                    let markerList = [];
                    let areaItem = JSON.parse(itemInner.QYZB);
                    if (areaItem.geometry.type == "MultiPolygon") {
                        areaItem.geometry.coordinates.map((item) => {
                            item.map((itemInner) => {
                                markerList = [...itemInner, ...markerList];
                            });
                        });
                    } else {
                        markerList = areaItem.geometry.coordinates[0];
                    }
                    let cross = turf.intersect(item, areaItem);
                    if (cross && !this.yczcList.includes(itemInner.QYMC)) {
                        this.yczcList.push(itemInner.QYMC);
                    }
                });
            });
            this.formdata.dataDic.YCZC = this.yczcList.join(",");
        },
        GetDataNext() {
            if (this.formdata.dataDic.KGRQ) {
                this.formdata.dataDic.KGRQ =
                    this.formdata.dataDic.KGRQ.split(" ")[0];
            } else {
                this.formdata.dataDic.KGRQ = null;
            }
            if (this.formdata.dataDic.JGSJ) {
                this.formdata.dataDic.JGSJ =
                    this.formdata.dataDic.JGSJ.split(" ")[0];
            } else {
                this.formdata.dataDic.JGSJ = null;
            }

            this.startTime = this.formdata.dataDic.KGRQ;
            this.endTime = this.formdata.dataDic.JGSJ;
            this.projectList = [];
            this.sytList = [];
            this.gcfapfwjList = [];
            this.formdata.dataDic.SFWJ = String(this.formdata.dataDic.SFWJ);
            if (this.formdata.metaJson && this.formdata.metaJson[0]) {
                this.projectList = this.formdata.metaJson[0];
            }
            if (this.formdata.metaJson && this.formdata.metaJson[1]) {
                this.gcfapfwjList = this.formdata.metaJson[1];
            }
            if (this.formdata.dataDic.XMDYID) {
                this.xmdyChange();
            }
        },
        xmdyChange() {
            this.fileData.mp3List = [];
            this.fileData.mp4List = [];
            this.fileData.imgList = [];
            let list = [];
            if (this.formdata.dataDic.XMWZ) {
                JSON.parse(this.formdata.dataDic.XMWZ).map((item) => {
                    if (!item.properties.from) {
                        list.push(item);
                    }
                });
            }

            if (this.formdata.dataDic.XMDYID) {
                let obj = this.common.aryListFindOne(
                    this.xmdyList,
                    "ID",
                    this.formdata.dataDic.XMDYID
                );
                if (!this.formdata.dataDic.XMMC) {
                    this.formdata.dataDic.XMMC = obj.XMMC;
                }
                let markerList = [
                    {
                        type: "Feature",
                        properties: {
                            from: "项目调研",
                        },
                        geometry: {
                            type: "Point",
                            coordinates: [obj.JD, obj.WD],
                        },
                    },
                ];
                this.formdata.dataDic.XMWZ = JSON.stringify([
                    ...list,
                    ...markerList,
                ]);
            } else {
                this.formdata.dataDic.XMWZ = JSON.stringify(list);
            }
            this.xmdyList.map((item) => {
                if (this.formdata.dataDic.XMDYID == item.ID) {
                    item.CHILD.map((itemInner) => {
                        let dw = itemInner.FJLJ.substring(
                            itemInner.FJLJ.lastIndexOf(".") + 1
                        );
                        switch (dw) {
                            case "mp3":
                                this.fileData.mp3List.push(itemInner.FJLJ);
                                break;
                            case "mp4":
                                this.fileData.mp4List.push(itemInner.FJLJ);
                                break;
                            case "jpg":
                            case "png":
                            case "jpeg":
                            case "gif":
                            case "tiff":
                                this.fileData.imgList.push(itemInner.FJLJ);
                                break;
                            default:
                                this.fileData.othersPart.list.push(itemInner);
                                break;
                        }
                    });
                }
            });
        },
        // 开始时间变化 后台需要 2020-01-01 这样的数据
        changeStartTime() {
            if (this.startTime) {
                this.formdata.dataDic.KGRQ = this.startTime + "-01";
            } else {
                this.formdata.dataDic.KGRQ = null;
            }
        },
        changeEndTime() {
            if (this.endTime) {
                this.formdata.dataDic.JGSJ = this.endTime + "-01";
            } else {
                this.formdata.dataDic.JGSJ = null;
            }
        },
    },
};
</script>

<style scoped>
.map {
    width: 100%;
    position: relative;
    height: 400px;
    float: left;
}
</style>
